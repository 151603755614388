<template>
  <div>
    <CForm @submit="submit" v-if="loaded">
      <!--Disable browser autocomplete-->
      <div style="display: none;">
        <input type="text" autocomplete="new-password">
        <input type="password" autocomplete="new-password">
      </div>
      <!--/Disable browser autocomplete-->
      <CCard>
        <CCardBody>

          <CAlert color="danger text-center" v-if="!_.isEmpty(errorMessage)" @update:show="errorMessage = ''">
            {{ errorMessage }}
          </CAlert>

          <CAlert color="success text-center" v-if="!_.isEmpty(sentMessage)" @update:show="sentMessage = ''">
            {{ sentMessage }}
          </CAlert>

          <div style="display: flex; justify-content: end;">
            <div :class="{ 'text-success': !blocked, 'text-muted': blocked }" class="text-bold" style="font-size: 12px;">{{ blocked ? $t('disabled') : $t('enabled') }}</div>
            &nbsp;
            <CSwitch
                class="mr-1"
                color="success"
                :checked="!blocked"
                variant="opposite"
                size="sm"
                @update:checked="toggleBlock"
            />
          </div>

          <div class="row">
            <div class="col-md-6 col-sm-12">
              <CInput
                  :label="$t('name')"
                  type="text"
                  v-model="name"
                  @keydown="resetErrors('name')"
                  :invalid-feedback="_.has(errors, 'name') ? errors.name : ''"
                  :is-valid="_.has(errors, 'name') ? false : null"
                  ref="name"
              >
              </CInput>
            </div>

            <div class="col-md-6 col-sm-12">
              <CInput
                  :label="$t('email')"
                  type="text"
                  v-model="email"
                  @keydown="resetErrors('email')"
                  :invalid-feedback="_.has(errors, 'email') ? errors.email : ''"
                  :is-valid="_.has(errors, 'email') ? false : null"
              >
              </CInput>
            </div>

            <div class="col-md-6 col-sm-12">
              <CInput
                  :label="$t('mobile')"
                  type="text"
                  v-model="mobile"
                  @keydown="resetErrors('mobile')"
                  :invalid-feedback="_.has(errors, 'mobile') ? errors.mobile : ''"
                  :is-valid="_.has(errors, 'mobile') ? false : null"
                  class="input-ltr-direction"
              >
              </CInput>
            </div>

            <div class="col-md-6 col-sm-12">
              <CSelect
                  :label="$t('role')"
                  :value.sync="role"
                  :options="[
                      {
                        value: 'system_admin',
                        label: $t('system_admin'),
                      },
                      {
                        value: 'normal_user',
                        label: $t('normal_user'),
                      }
                  ]"
                  :placeholder="$t('please_select_a_user_role')"
                  @click="resetErrors('role')"
                  :invalid-feedback="_.has(errors, 'role') ? errors.role : ''"
                  :is-valid="_.has(errors, 'role') ? false : null"
              />
            </div>

            <div class="col-md-6 col-sm-12">
              <CInput
                  :label="$t('password')"
                  :type="passwordIsVisible ? 'text' : 'password'"
                  v-model="password"
                  @keydown="resetErrors('password')"
                  :invalid-feedback="_.has(errors, 'password') ? errors.password : ''"
                  :is-valid="_.has(errors, 'password') ? false : null"
              >
                <template #append>
                  <div class="wy-password-visible-container"
                       :class="{ 'wy-has-error': _.has(errors, 'password') }"
                       @click="passwordIsVisible = !passwordIsVisible">
                    <i :class="{ 'wy-icon-visibility-open': passwordIsVisible, 'wy-icon-visibility-close': !passwordIsVisible }" style="font-size: 22px;"></i>
                  </div>
                </template>
              </CInput>
            </div>

            <div class="col-md-6 col-sm-12">
              <CInput
                  :label="$t('password_confirmation')"
                  :type="passwordIsVisibleConfirmation ? 'text' : 'password'"
                  v-model="passwordConfirmation"
                  @keydown="resetErrors('passwordConfirmation')"
                  :invalid-feedback="_.has(errors, 'passwordConfirmation') ? errors.passwordConfirmation : ''"
                  :is-valid="_.has(errors, 'passwordConfirmation') ? false : null"
              >
                <template #append>
                  <div class="wy-password-visible-container"
                       :class="{ 'wy-has-error': _.has(errors, 'passwordConfirmation') }"
                       @click="passwordIsVisibleConfirmation = !passwordIsVisibleConfirmation">
                    <i :class="{ 'wy-icon-visibility-open': passwordIsVisibleConfirmation, 'wy-icon-visibility-close': !passwordIsVisibleConfirmation }" style="font-size: 22px;"></i>
                  </div>
                </template>
              </CInput>
            </div>
          </div>

        </CCardBody>
        <CCardFooter>
          <div class="row">
            <div class="col">
              <CButton type="submit" size="sm" color="red" class="px-4">
                <CIcon name="cil-check-circle"/>
                {{ $t('save') }}
              </CButton>
            </div>
            <div class="col text-right">
<!--              <CButton type="reset" size="sm" color="secondary" class="px-4">-->
<!--                <CIcon name="cil-ban"/>-->
<!--                {{ $t('reset') }}-->
<!--              </CButton>-->
            </div>
          </div>
        </CCardFooter>
      </CCard>
    </CForm>
  </div>
</template>

<script>
import {getUser, insertUser, updateUser} from "../../../api/user";
import email from 'email-validator';
import scrollToFirstClass from '../../../mixins/DashboardScrollToFirstClass';

export default {
  name: 'User',
  mixins: [
    scrollToFirstClass,
  ],
  data() {
    return {
      passwordIsVisible: false,
      passwordIsVisibleConfirmation: false,
      errors: {},
      error: '',
      sentMessage: '',
      errorMessage: '',
      //
      id: '',
      name: '',
      email: '',
      mobile: '',
      role: '',
      password: '',
      passwordConfirmation: '',
      blocked: false,
      //
      isEdit: false,
      loaded: false,
    };
  },
  mounted() {
    this.tryGetUser();
  },
  methods: {
    resetErrors(property) {
      this.sentMessage = '';
      if (!this._.isUndefined(property)) {
        delete this.errors[property];
      } else {
        this.errors = {};
        this.error = ''
      }
    },
    tryGetUser() {
      if (this._.has(this.$route.params, 'user_id') && /^\d+$/.test(this.$route.params.user_id)) {
        this.isEdit = true;
        getUser(this, this.$route.params.user_id, (response) => {
          this.id = response.user.id;
          this.name = response.user.name;
          this.email = response.user.email;
          this.mobile = response.user.mobile;
          this.role = response.user.role;
          this.blocked = response.user.blocked;
          this.loaded = true;
        }, (error) => {
          this.error = error.message;
        });
      } else {
        this.loaded = true;
      }
    },
    validate(callback) {
      let hasErrors = false;
      this.resetErrors();

      if (this.name === '') {
        this.errors.name = this.$t('field_is_required', { field: this.$t('name') });
        hasErrors = true
      }

      if (this.email === '') {
        this.errors.email = this.$t('field_is_required', { field: this.$t('email') });
        hasErrors = true
      }

      if (this.mobile === '') {
        this.errors.mobile = this.$t('field_is_required', { field: this.$t('mobile') });
        hasErrors = true
      }

      if (this.role === '') {
        this.errors.role = this.$t('field_is_required', { field: this.$t('role') });
        hasErrors = true
      }

      if (!email.validate(this.email)) {
        this.errors.email = this.$t('wrong_email');
        hasErrors = true
      }

      if (this.password === '' && !this.isEdit) {
        this.errors.password = this.$t('field_is_required_IN_ARABIC_FEMALE', { field: this.$t('password') });
        hasErrors = true
      }

      if (this.passwordConfirmation !== this.password) {
        this.errors.passwordConfirmation = this.$t('the_password_confirmation_must_be_equal_to_the_password');
        hasErrors = true
      }

      this.$forceUpdate();
      if (!hasErrors) {
        callback()
      } else {
        this.scrollToFirstClass('is-invalid');
      }
    },
    submit(event) {
      if (this._.isFunction(event.preventDefault)) {
        event.preventDefault();
      }
      this.sentMessage = '';
      this.errorMessage = '';
      this.validate(() => {
        if (!this.isEdit) {
          insertUser(this, this.name, this.email, this.mobile, this.role, this.password, this.blocked, (response) => {
            this.sentMessage = response.message;
            this.name = '';
            this.email = '';
            this.mobile = '';
            this.role = '';
            this.password = '';
            this.passwordConfirmation = '';
            this.blocked = false;
            this.$router.push({name: 'Users'});
          }, (error) => {
            if (this._.has(error, 'errors.name')) {
              this.errors.name = error.errors.name[0];
              this.$forceUpdate();
            }
            if (this._.has(error, 'errors.email')) {
              this.errors.email = error.errors.email[0];
              this.$forceUpdate();
            }
            if (this._.has(error, 'errors.mobile')) {
              this.errors.mobile = error.errors.mobile[0];
              this.$forceUpdate();
            }
            if (this._.has(error, 'errors.role')) {
              this.errors.role = error.errors.role[0];
              this.$forceUpdate();
            }
            if (this._.has(error, 'errors.password')) {
              this.errors.password = error.errors.password[0];
              this.$forceUpdate();
            }
            this.scrollToFirstClass('is-invalid');
          });
        } else {
          updateUser(this, this.id, this.name, this.email, this.mobile, this.role, this.password, this.blocked, (response) => {
            this.sentMessage = response.message;
            this.$router.push({name: 'Users'});
          }, (error) => {
            if (this._.has(error, 'errors.name')) {
              this.errors.name = error.errors.name[0];
              this.$forceUpdate();
            }
            if (this._.has(error, 'errors.email')) {
              this.errors.email = error.errors.email[0];
              this.$forceUpdate();
            }
            if (this._.has(error, 'errors.mobile')) {
              this.errors.mobile = error.errors.mobile[0];
              this.$forceUpdate();
            }
            if (this._.has(error, 'errors.role')) {
              this.errors.role = error.errors.role[0];
              this.$forceUpdate();
            }
            if (this._.has(error, 'errors.password')) {
              this.errors.password = error.errors.password[0];
              this.$forceUpdate();
            }
            if (this._.has(error, 'custom_message')) {
              this.errorMessage = error.custom_message;
              this.$forceUpdate();
            }
            this.scrollToFirstClass('is-invalid');
          });
        }
      });
    },
    toggleBlock() {
      this.blocked = !this.blocked;
    },
  },
}
</script>
